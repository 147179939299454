exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cfp-js": () => import("./../../../src/pages/cfp.js" /* webpackChunkName: "component---src-pages-cfp-js" */),
  "component---src-pages-coc-js": () => import("./../../../src/pages/coc.js" /* webpackChunkName: "component---src-pages-coc-js" */),
  "component---src-pages-combo-tickets-js": () => import("./../../../src/pages/combo-tickets.js" /* webpackChunkName: "component---src-pages-combo-tickets-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-diversity-js": () => import("./../../../src/pages/diversity.js" /* webpackChunkName: "component---src-pages-diversity-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-hotel-js": () => import("./../../../src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-program-index-js": () => import("./../../../src/pages/program/index.js" /* webpackChunkName: "component---src-pages-program-index-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-index-js": () => import("./../../../src/pages/speakers/index.js" /* webpackChunkName: "component---src-pages-speakers-index-js" */),
  "component---src-pages-sponsor-us-js": () => import("./../../../src/pages/sponsor-us.js" /* webpackChunkName: "component---src-pages-sponsor-us-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-templates-blog-js-content-file-path-content-blog-ralph-debussman-q-and-a-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/blog/ralph-debussman-q-and-a.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-ralph-debussman-q-and-a-mdx" */),
  "component---src-templates-blog-js-content-file-path-content-blog-workshops-mdx": () => import("./../../../src/templates/blog.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/blog/workshops.mdx" /* webpackChunkName: "component---src-templates-blog-js-content-file-path-content-blog-workshops-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-accelerating-integrations-at-wix-with-semantic-messaging-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/accelerating-integrations-at-wix-with-semantic-messaging.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-accelerating-integrations-at-wix-with-semantic-messaging-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-automating-business-processes-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/automating-business-processes.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-automating-business-processes-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-bringing-clarity-to-event-streams-enabling-analytics-and-ai-through-rich-metadata-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/bringing-clarity-to-event-streams-enabling-analytics-and-ai-through-rich-metadata.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-bringing-clarity-to-event-streams-enabling-analytics-and-ai-through-rich-metadata-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-cqrs-and-event-sourcing-in-the-age-of-durable-execution-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/cqrs-and-event-sourcing-in-the-age-of-durable-execution.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-cqrs-and-event-sourcing-in-the-age-of-durable-execution-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-designing-microservices-responsibilities-apis-and-collaborations-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/designing-microservices-responsibilities-apis-and-collaborations.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-designing-microservices-responsibilities-apis-and-collaborations-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-eliminate-the-complexities-of-event-driven-architecture-with-domain-driven-design-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-event-driven-modelling-anti-patterns-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/event-driven-modelling-anti-patterns.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-event-driven-modelling-anti-patterns-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-backbone-of-agentic-ai-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/event-sourcing-the-backbone-of-agentic-ai.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-backbone-of-agentic-ai-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-what-why-and-how-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/event-sourcing-the-what-why-and-how.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-event-sourcing-the-what-why-and-how-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-getting-your-kafka-costs-under-control-a-quest-for-more-transparency-and-accountability-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-harnessing-the-durable-execution-pattern-from-failures-to-fault-tolerance-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-orchestration-vs-choreography-the-good-the-bad-and-the-trade-offs-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-our-journey-to-an-event-sourcing-middleware-eurostar-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/our-journey-to-an-event-sourcing-middleware-eurostar.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-our-journey-to-an-event-sourcing-middleware-eurostar-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-practical-introduction-to-event-sourcing-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/practical-introduction-to-event-sourcing.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-practical-introduction-to-event-sourcing-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-so-you-want-to-maintain-a-reliable-event-driven-system-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/so-you-want-to-maintain-a-reliable-event-driven-system.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-so-you-want-to-maintain-a-reliable-event-driven-system-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-architect-elevator-visiting-the-upper-floors-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/the-architect-elevator-visiting-the-upper-floors.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-architect-elevator-visiting-the-upper-floors-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-the-magic-of-small-things-microservices-10-years-on-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/the-magic-of-small-things-microservices-10-years-on.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-the-magic-of-small-things-microservices-10-years-on-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-unifying-databases-and-streaming-systems-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/unifying-databases-and-streaming-systems.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-unifying-databases-and-streaming-systems-mdx" */),
  "component---src-templates-session-js-content-file-path-content-program-versioning-event-driven-systems-change-is-inevitable-mdx": () => import("./../../../src/templates/session.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/program/versioning-event-driven-systems-change-is-inevitable.mdx" /* webpackChunkName: "component---src-templates-session-js-content-file-path-content-program-versioning-event-driven-systems-change-is-inevitable-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-acerbis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/alberto-acerbis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-acerbis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-brandolini-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/alberto-brandolini.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alberto-brandolini-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alessandro-colla-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/alessandro-colla.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alessandro-colla-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-alexander-rovner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/alexander-rovner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-alexander-rovner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-andrea-gioia-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/andrea-gioia.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-andrea-gioia-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-harmel-law-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/andrew-harmel-law.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-harmel-law-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-jones-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/andrew-jones.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-andrew-jones-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-anita-kvamme-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/anita-kvamme.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-anita-kvamme-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-annegret-junker-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/annegret-junker.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-annegret-junker-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-arif-wider-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/arif-wider.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-arif-wider-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-baptiste-mace-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/baptiste-mace.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-baptiste-mace-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-barry-oreilly-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/barry-oreilly.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-barry-oreilly-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-beija-nigl-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/beija-nigl.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-beija-nigl-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chelsea-troy-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/chelsea-troy.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chelsea-troy-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chris-richardson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/chris-richardson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chris-richardson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-chris-simon-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/chris-simon.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-chris-simon-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-clemens-vasters-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/clemens-vasters.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-clemens-vasters-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-cyrille-martraire-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/cyrille-martraire.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-cyrille-martraire-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-david-whitney-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/david-whitney.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-david-whitney-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-davy-landman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/davy-landman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-davy-landman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dorra-bartaguiz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/dorra-bartaguiz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dorra-bartaguiz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dr-jabe-bloom-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/dr-jabe-bloom.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dr-jabe-bloom-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-dr-simon-harrer-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/dr-simon-harrer.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-dr-simon-harrer-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-emma-houben-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/emma-houben.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-emma-houben-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-eric-evans-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/eric-evans.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-eric-evans-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-fabrice-bernhard-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/fabrice-bernhard.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-fabrice-bernhard-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ferdinand-ade-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/ferdinand-ade.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ferdinand-ade-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-gien-verschatse-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/gien-verschatse.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-gien-verschatse-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-gregor-hohpe-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/gregor-hohpe.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-gregor-hohpe-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-helen-rapp-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/helen-rapp.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-helen-rapp-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-henning-schwentner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/henning-schwentner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-henning-schwentner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ian-cooper-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/ian-cooper.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ian-cooper-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jacek-majchrzak-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/jacek-majchrzak.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jacek-majchrzak-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jacqui-read-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/jacqui-read.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jacqui-read-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-james-eastham-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/james-eastham.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-james-eastham-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-james-lewis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/james-lewis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-james-lewis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jan-van-ryswyck-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/jan-van-ryswyck.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jan-van-ryswyck-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jeremy-davis-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/jeremy-davis.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jeremy-davis-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-joao-rosa-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/joao-rosa.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-joao-rosa-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jochen-christ-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/jochen-christ.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jochen-christ-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-john-cutler-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/john-cutler.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-john-cutler-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-john-phamvan-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/john-phamvan.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-john-phamvan-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-jorg-schad-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/jorg-schad.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-jorg-schad-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-josian-chevalier-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/josian-chevalier.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-josian-chevalier-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-juha-pekka-tolvanen-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/juha-pekka-tolvanen.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-juha-pekka-tolvanen-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-julien-topcu-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/julien-topcu.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-julien-topcu-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-karin-hakansson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/karin-hakansson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-karin-hakansson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-karol-skrzymowski-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/karol-skrzymowski.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-karol-skrzymowski-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kenny-baas-schwegler-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/kenny-baas-schwegler.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kenny-baas-schwegler-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-hoffman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/kevin-hoffman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-hoffman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-meadows-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/kevin-meadows.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kevin-meadows-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kinda-el-maarry-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/kinda-el-maarry.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kinda-el-maarry-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kiran-prakash-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/kiran-prakash.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kiran-prakash-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-krisztina-hirth-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/krisztina-hirth.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-krisztina-hirth-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-kyle-kotowick-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/kyle-kotowick.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-kyle-kotowick-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-laila-bougria-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/laila-bougria.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-laila-bougria-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-leander-vanderbijl-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/leander-vanderbijl.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-leander-vanderbijl-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-lukasz-reszke-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/lukasz-reszke.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-lukasz-reszke-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-marc-klefter-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/marc-klefter.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-marc-klefter-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mark-richards-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/mark-richards.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mark-richards-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-martin-gunther-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/martin-gunther.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-martin-gunther-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-maxime-sanglan-charlier-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/maxime-sanglan-charlier.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-maxime-sanglan-charlier-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-michael-plod-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/michael-plod.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-michael-plod-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mihai-budiu-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/mihai-budiu.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mihai-budiu-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mike-wojtyna-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/mike-wojtyna.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mike-wojtyna-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-mufrid-krilic-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/mufrid-krilic.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-mufrid-krilic-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-natan-silnitsky-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/natan-silnitsky.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-natan-silnitsky-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-neal-ford-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/neal-ford.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-neal-ford-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-omphile-matheolane-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/omphile-matheolane.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-omphile-matheolane-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-oskar-dudycz-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/oskar-dudycz.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-oskar-dudycz-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-pamela-paterson-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/pamela-paterson.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-pamela-paterson-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-paolo-rodeghiero-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/paolo-rodeghiero.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-paolo-rodeghiero-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-paul-rayner-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/paul-rayner.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-paul-rayner-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-philipp-kostyra-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/philipp-kostyra.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-philipp-kostyra-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-pieter-herroelen-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/pieter-herroelen.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-pieter-herroelen-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-prem-chandrasekaran-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/prem-chandrasekaran.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-prem-chandrasekaran-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-rachel-barton-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/rachel-barton.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-rachel-barton-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ralph-m-debusmann-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/ralph-m-debusmann.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ralph-m-debusmann-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-rebecca-wirfs-brock-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/rebecca-wirfs-brock.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-rebecca-wirfs-brock-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-ruth-malan-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/ruth-malan.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-ruth-malan-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-sheen-brisals-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/sheen-brisals.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-sheen-brisals-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-simon-brown-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/simon-brown.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-simon-brown-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-sung-shik-jongmans-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/sung-shik-jongmans.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-sung-shik-jongmans-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-coopman-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/thomas-coopman.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-coopman-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-seifert-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/thomas-seifert.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-thomas-seifert-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-tobias-goeschel-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/tobias-goeschel.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-tobias-goeschel-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-trond-hjorteland-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/trond-hjorteland.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-trond-hjorteland-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-woody-zuill-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/woody-zuill.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-woody-zuill-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-xin-yao-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/xin-yao.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-xin-yao-mdx" */),
  "component---src-templates-speaker-js-content-file-path-content-speakers-younes-zeriahi-mdx": () => import("./../../../src/templates/speaker.js?__contentFilePath=/home/runner/work/2025.eventcentric.eu/2025.eventcentric.eu/content/speakers/younes-zeriahi.mdx" /* webpackChunkName: "component---src-templates-speaker-js-content-file-path-content-speakers-younes-zeriahi-mdx" */)
}

